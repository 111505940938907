import { DEFAULT_SPACING } from "constants/spacing";

const Header = {
  baseStyle: {
    border: "1px solid",
    display: "flex",
    borderColor: "body.400",
    alignItems: "center",
    padding: DEFAULT_SPACING,
  }
};

export { Header };
